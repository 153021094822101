import React from "react";

import CalendarBox from "./calendarbox";

const EmptyDays = ({ days }) => (
  <>
    {Array(days)
      .fill(null)
      .map((_, index) => (
        <CalendarBox key={index} isEmpty>
          {" "}
        </CalendarBox>
      ))}
  </>
);

export default EmptyDays;
