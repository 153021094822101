import React from "react";
import moment from "moment";
import classnames from "classnames";
import sortByDate from "../../utils/sortByDate";
import dotIcon from "../../images/icons/dot.svg";

import Event from "./event";

const Events = ({ events, hasPast }) => (
  <>
    <div
      className={classnames("hidden md:block", {
        "text-sage": hasPast
      })}
    >
      {events.sort(sortByDate).map((event, index) => (
        <Event
          name={event.data.event_name.text}
          key={index}
          time={moment(`${event.data.date}`).format("h:mm a")}
          isPrivate={event.data.private}
        />
      ))}
    </div>
    {events.length > 0 && (
      <div className="md:hidden">
        <img src={dotIcon} alt="See Events" className="mx-auto" />
      </div>
    )}
  </>
);

export default Events;
