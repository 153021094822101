import React from "react";
import moment from "moment";

const getWeekdays = () => {
  const startDate = moment().startOf("week");
  const endDate = moment().endOf("week");
  const loopDate = startDate.clone();
  const dates = [];

  while (loopDate.isSameOrBefore(endDate)) {
    dates.push({
      desktop: loopDate.format("dddd"),
      mobile: loopDate.format("dd")
    });
    loopDate.add(1, "days");
  }
  return dates;
};

const Weekdays = () => {
  const weekdays = getWeekdays();
  return (
    <div className="flex pb-2">
      {weekdays.map((weekday, index) => (
        <div className="w-1/7 font-medium text-center md:text-left" key={index}>
          <span className="hidden md:block">{weekday.desktop}</span>
          <span className="md:hidden">{weekday.mobile}</span>
        </div>
      ))}
    </div>
  );
};

export default Weekdays;
