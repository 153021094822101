import React from "react";
import classnames from "classnames";

const CalendarBox = ({ children, onClick, isEmpty }) => (
  <div
    className={classnames(
      "w-1/7 p-2 md:p-3 h-12 md:h-40 md:border text-center md:text-left",
      {
        "cursor-pointer": onClick,
        "md:bg-cream-light": !isEmpty
      }
    )}
    onClick={onClick}
  >
    {children}
  </div>
);

export default CalendarBox;
