import React from "react";

const Event = ({ name, time, isPrivate }) => (
  <div className={`hidden md:block pb-2 ${isPrivate ? "text-gray-600" : ""}`}>
    {isPrivate && (
      <div className="bg-sage text-white rounded">
        <p className="px-1 text-white text-xs leading-tight font-bold">
          Private Event
        </p>
      </div>
    )}
    <p className="font-bold text-sm">{time}</p>
    <p className={`text-sm leading-tight`}>{name}</p>
  </div>
);

export default Event;
