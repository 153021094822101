import React from "react";
import moment from "moment";
import classnames from "classnames";

import CalendarBox from "./calendarbox";
import Events from "./events";

const Day = ({ day, events, onClick }) => {
  const today = moment();
  const isToday = day.isSame(today, "day");
  const hasPast = day.isBefore(today, "day");

  return (
    <CalendarBox {...(events.length && { onClick })}>
      <div
        className={classnames("md:pb-2 text-sm md:text-base", {
          "text-sage": hasPast,
          "text-carmine font-bold": isToday
        })}
      >
        {day.format("DD")}
      </div>
      {events && <Events events={events} hasPast={hasPast} />}
    </CalendarBox>
  );
};

const Days = ({ days, events, month, showModal }) =>
  Array(days)
    .fill(null)
    .map((_, index) => {
      const currentDay = month.clone().add(index, "days");
      const eventsOfTheDay =
        events &&
        events.filter(event => currentDay.isSame(event.data.date, "day"));
      const onClick = () => showModal(eventsOfTheDay, currentDay);
      return (
        <Day
          key={index}
          day={currentDay}
          events={eventsOfTheDay}
          onClick={onClick}
        />
      );
    });

export default Days;
