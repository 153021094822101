import moment from "moment";

const isGreaterInMonth = monthsDifference => (date, dateToCompare) => {
  const totalMonth = dateProp =>
    parseInt(moment(dateProp).format("MM"), 10) +
    parseInt(moment(dateProp).format("YY"), 10) * 12;

  const monthsDate = totalMonth(date);
  const monthsDateToCompare = totalMonth(dateToCompare);
  const difference = monthsDateToCompare - monthsDate;

  return difference >= 0 && difference <= monthsDifference;
};

const groupEventsByMonth = (data, monthsDifference) => {
  const today = new Date();
  const isEventValid = isGreaterInMonth(monthsDifference);

  const eventsByMonthKey = data.allPrismicEvent.edges.reduce(
    (acc, { node }) => {
      const eventDate = new Date(node.data.date);
      if (!isEventValid(today, eventDate)) return acc;

      const monthYear = moment(eventDate).format("YYYY-MM");
      if (!acc[monthYear]) {
        return {
          ...acc,
          [monthYear]: [node]
        };
      }

      return {
        ...acc,
        [monthYear]: acc[monthYear].concat(node)
      };
    },
    {}
  );
  const result = Object.keys(eventsByMonthKey).map(monthKey => ({
    events: eventsByMonthKey[monthKey],
    date: monthKey
  }));
  return result;
};

export default groupEventsByMonth;
