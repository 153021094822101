import React from "react";
import moment from "moment";
import Month from "./month";

const Calendar = ({ showModal, events }) => (
  <div>
    {Array.from({ length: 3 }).map((_, index) => {
      const currentMonth = moment()
        .add(index, "months")
        .startOf("month");
      const filteredEvents = events.filter(event => {
        return moment(event.date).isSame(currentMonth, "month");
      });

      const monthsEvents = filteredEvents.length
        ? filteredEvents[0].events
        : [];

      return (
        <Month
          month={currentMonth}
          events={monthsEvents}
          key={currentMonth}
          showModal={showModal}
        />
      );
    })}
  </div>
);

export default Calendar;
