import React from "react";
import moment from "moment";
import sortByDate from "../utils/sortByDate";
import closeIcon from "../images/icons/close.svg";
import RichText from "./richtext";

const ModalEvent = ({ hideModal, currentDay, events }) => {
  return (
    <div
      className="fixed inset-0 flex items-center justify-center "
      onClick={hideModal}
    >
      <div className="bg-cream-light p-6 w-full max-w-xs shadow relative max-h-3/4 overflow-scroll my-8">
        <button
          onClick={hideModal}
          className="ml-auto block w-10 absolute top-0 right-0 p-3"
        >
          <img src={closeIcon} alt="Close" />
        </button>
        <p className="pb-2 font-bold text-sm">
          {currentDay.format("MMM Do YYYY")}
        </p>
        {events.sort(sortByDate).map((event, index) => (
          <div key={index} className="pb-4 border-t pt-2">
            <div className="flex justify-between items-center">
              <p className="font-bold text-sm">
                {moment(`${event.data.date}`).format("h:mm a")}
              </p>
              {event.data.private && (
                <div className="bg-sage text-white px-1 rounded inline-block">
                  <p className="text-xs font-bold px-1">Private Event</p>
                </div>
              )}
            </div>

            <h4 className="font-bold text-xl leading-tight mb-2">
              {event.data.event_name.text}
            </h4>

            <p className="mb-3">
              <span className="font-bold">Where:</span> {event.data.location}
            </p>
            {event.data.description && (
              <RichText html={event.data.description.html} />
            )}
            {event.data.event_link.url && (
              <a
                href={event.data.event_link.url}
                className="text-sm underline hover:no-underline"
                target="_blank"
              >
                Link Here
              </a>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
export default ModalEvent;
