import React from "react";
import moment from "moment";

import Weekdays from "./weekdays";
import Days from "./days";
import EmptyDays from "./emptydays";

const Month = ({ events, month, showModal }) => {
  const currentMonthIsoDay = month.isoWeekday();
  const currentMonthDays = month.daysInMonth();
  const emptyDaysAtEnd = 7 - ((currentMonthIsoDay + currentMonthDays) % 7);

  return (
    <div className="mb-10">
      <h2 className="text-center font-serif text-xl md:text-3xl mb-4 md:mb-8">
        {month.format("MMMM YYYY")}
      </h2>
      <Weekdays />
      <div className="flex flex-wrap">
        {currentMonthIsoDay !== 7 && <EmptyDays days={currentMonthIsoDay} />}
        <Days
          days={currentMonthDays}
          month={month}
          events={events}
          showModal={showModal}
        />
        {emptyDaysAtEnd !== 7 && <EmptyDays days={emptyDaysAtEnd} />}
      </div>
    </div>
  );
};
export default Month;
