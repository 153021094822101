import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import moment from "moment";

import Layout from "../components/layout";
import SEO from "../components/seo";
import PageTitle from "../components/pagetitle";
import Calendar from "../components/calendar";
import ModalEvent from "../components/modalevent";
import RichText from "../components/richtext";

import iconPlus from "../images/icons/plus.svg";

import groupEventsByMonth from "../utils/groupEventsByMonth";

const EventsPage = ({ data }) => {
  const [currentDay, setCurrentDay] = useState(moment());
  const [eventsOfTheDay, setEventsOfTheDay] = useState([]);
  const [showModal, toggleShowModal] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined" && !!window.document) {
      const html = document.querySelector("html");
      showModal
        ? (html.style.overflow = "hidden")
        : (html.style.overflow = "visible");
    }
  }, [showModal]);

  const handleShowModal = (newEventsOfTheDay, newCurrentDay) => {
    setCurrentDay(newCurrentDay);
    setEventsOfTheDay(newEventsOfTheDay);
    toggleShowModal(true);
  };

  return (
    <Layout>
      <SEO title="Events" />
      <div className="flex justify-between">
        <PageTitle text={data.prismicEventsPage.data.title.text} />
        <a
          href="mailto:mckinstryelizabeth@gmail.com"
          className="btn btn-primary w-8 h-8 md:h-auto md:w-40 self-start mb-4 p-2"
        >
          <span className="hidden md:block">Add Event</span>
          <span className="md:hidden">
            <img src={iconPlus} alt="Add Event" />
          </span>
        </a>
      </div>
      <RichText html={data.prismicEventsPage.data.intro.html} />

      <Calendar
        showModal={handleShowModal}
        events={groupEventsByMonth(data, 12)}
      />
      {showModal && (
        <ModalEvent
          hideModal={() => toggleShowModal(!showModal)}
          currentDay={currentDay}
          events={eventsOfTheDay}
        />
      )}
    </Layout>
  );
};

export const query = graphql`
  {
    allPrismicEvent(sort: { fields: data___date }) {
      edges {
        node {
          data {
            date
            description {
              html
              text
            }
            event_link {
              url
            }
            event_name {
              text
            }
            location
            private
          }
        }
      }
    }
    prismicEventsPage {
      data {
        title {
          text
        }
        intro {
          html
        }
      }
    }
  }
`;

export default EventsPage;
